// import {
//   MediaKind,
//   RtpCapabilities,
//   RtpParameters,
// } from "mediasoup-client/lib/RtpParameters";
import SocketService from "../services/socket";
import {
  mediaTypeConstant,
  REACT_APP_CAMERA_OFF_NOTIFICATIONS,
  CAMERA_NOTIFICATION_TIMER_INTERVAL,
  resolutionConstant,
  role,
  roomViewModeConst,
  screenResolution,
  socketConnectiontype,
  AGENT_CAM_AUTO_ON,
  AGENT_CAM_ALWAYS_ON,
} from "../utils/constant";
import {
  checkVideoDevices,
  getAudioStream,
  getScreenStream,
  getVideoStream,
} from "../utils/AudioVideoDevices";
import {
  RemotePeerData,
  RemotePeerStreamInterface,
  TFLite,
} from "../interfaces/componentsInterface/mediaInterface";
import MediasoupService from "../services/mediasoupService";
import toast from "react-hot-toast";
import { constantMethod } from "../utils/constantMethod";
import { store } from "../app/store";
import {
  audioTurnOrOff,
  screenTurnOrOff,
  screenTwoTurnOrOff,
  setIsAudioProducerCreated,
  setIsScreenAudioShared,
  setVideoAccessibleforPeer,
  setIsVideoProducerCreated,
  updateRemotePeer,
  videoTurnOrOff,
  setVideoPauseAlertInterval,
  clearVideoPauseAlertInterval,
  setFireAlertForCam,
} from "../features/media/mediaSlice";
import config from "../config";
import { sleep } from "../utils/sleep";
import hark from "hark";
import { setPeerVolume } from "../features/peerVolume/peerVolumeSlice";
import {
  setAudioFile,
  setLocalAudioFilePaused,
  setLocalScreenFileStopped,
  setVideoFile,
} from "../features/localFileStream/localFileStreamSlice";
import {
  setIsReconnectionStatusAudio,
  setIsReconnectionStatusScreenForElectron,
  setIsReconnectionStatusScreenOne,
  setIsReconnectionStatusScreenTwo,
  setIsReconnectionStatusVideo,
} from "../features/reconnectionStatus/reconnectionStatusSlice";
import { setImageLink, setIsVirtualModalOpen } from "../features/videoBackground/videoBackgroundSlice";
import { setVideoOption } from "../features/videoOption/videoOptionSlice";
import { convertIntoBoolean } from "../utils/getboolean";
import { clearVerifyFace } from "../features/verifyFace/verifyFaceSlice";
import watchRTC from "@testrtc/watchrtc-sdk";
import { setCaller } from "../features/privateCall/privateCallSlice";
import { notifyMe } from "../utils/desktopNotification";

const mediasoupInstance = new MediasoupService().getInstance();

const socketInstance = new SocketService(
  socketConnectiontype.media
).getInstance(socketConnectiontype.media);

const socketInstanceDesktopApp = new SocketService(
  socketConnectiontype.desktopApp
).getInstance(socketConnectiontype.desktopApp);

let replaceVideoTimeout: ReturnType<typeof setTimeout> | null;

class StreamService {
  localVideoStream: MediaStream | undefined | null;
  localScreenStream: MediaStream | undefined;
  localSecondScreenStream: MediaStream | undefined;
  localAudioStream: MediaStream | undefined | null;
  systemAudioFromElectron: any | undefined;
  peerRemoteStream: RemotePeerStreamInterface | null;
  private _hark: any;
  _harkStream: any;
  localAudioFileStream: MediaStream | undefined;
  localScreenFileStream: MediaStream | undefined;
  intervalForElectronScreenControl: any;
  selectedTflite: TFLite | any;
  videoTrack: any;
  _videoInUse: boolean = false;

  constructor() {
    this.peerRemoteStream = {};
  }

  getEncodings = () => {
    let mediaReducer = store.getState().media;
    let meetingRoomReducer = store.getState().meetingRoom;
    let roomReducer = store.getState().room;
    let { role: currentUserRole, userId: selfUserId } = store.getState().user;

    return [
      {
        maxBitrate: roomReducer.videoScale.maxBitrate,
        scaleResolutionDownBy:
          (constantMethod.checkHigherRole(currentUserRole) &&
            roomReducer.viewMode === roomViewModeConst.lectureMode) ||
            selfUserId === mediaReducer.presenter ||
            (roomReducer.viewMode === roomViewModeConst.collabMode &&
              !meetingRoomReducer.screenCollabPeerArray.length) // If no one sharing screen then produce HIGH RES Video in collab room
            ? roomReducer.videoScale.HIGH
            : roomReducer.videoScale.LOW,
      },
    ];
  };

  getEncodingsForScreen = () => {
    let mediaReducer = store.getState().media;
    let roomReducer = store.getState().room;
    let { role: currentUserRole, userId: selfUserId } = store.getState().user;
    return [
      {
        maxBitrate: roomReducer.screenScale.maxBitrate,
        scaleResolutionDownBy:
          constantMethod.checkHigherRole(currentUserRole) ||
            roomReducer.viewMode === roomViewModeConst.collabMode ||
            selfUserId === mediaReducer.presenter
            ? roomReducer.screenScale.HIGH
            : roomReducer.screenScale.LOW,
      },
    ];
  };


  checkVideoDeviceStatus = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
  
      if (videoDevices.length > 0) {
        let isInUse = false;
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          const videoTracks = stream.getVideoTracks();
  
          isInUse = videoTracks.some(track => track.readyState === 'live');
          if (document.title !== "CollaborationRoom.AI") {

            this._videoInUse = isInUse;
          }
  
          videoTracks.forEach(track => track.stop());
        } catch (err) {
          console.error('Error accessing video devices:', err);
          toast.error('Error in connecting your camera');
          this._videoInUse = true;
          return;
        }
  
        if (document.title !== "CollaborationRoom.AI") {
          if (isInUse) {
            toast.error('Error in connecting your camera');
          }
        }
      } else {
        this._videoInUse = false;
      }
    } catch (error) {
      console.error('Error accessing video devices:', error);
      toast.error('Error in connecting your camera');
      this._videoInUse = true;
    }
  };
  

  startVideoStream = async (
    activeVideoDevice: string,
    faceDetection: () => void
  ) => {
    try {
      let reconnectionReducer = store.getState().reconnectionStatus;
      let encodings = this.getEncodings();

      let videoDeviceFound = await checkVideoDevices();

      let { role, userId } = store.getState().user;
      let { currentRoom, currentRoomId } = store.getState().room




      if (videoDeviceFound) {
        console.log({ videoInUse: this._videoInUse })
        if (this._videoInUse) {
          console.log("HELLLLLLLLLLLLLLLLLLLLLLLll")
          store.dispatch(setVideoAccessibleforPeer(false))
          socketInstance.sendMessage("SET_VIDEO_ACCESSIBLE", {
            userId: userId,
            roomId: currentRoomId,
            videoAccessible: false
          })
          return;
        }


        if (mediasoupInstance.videoProducer) {
          mediasoupInstance.videoProducer.close();
        }

        if (this.videoTrack) {
          this.videoTrack.stop();
          this.videoTrack = null;
        }

        if (this.localVideoStream) {
          this.localVideoStream.getVideoTracks().forEach(function (track: any) {
            track.stop();
          });
          this.localVideoStream = null;
        }

        if (activeVideoDevice !== "") {
          this.localVideoStream = await getVideoStream(activeVideoDevice);
        }

        if (this.localVideoStream && mediasoupInstance.publishTransport) {
          mediasoupInstance.videoProducer =
            await mediasoupInstance.publishTransport.produce({
              track: this.localVideoStream.getVideoTracks()[0],
              encodings: encodings,
              zeroRtpOnPause: true,
              codecOptions: {
                videoGoogleMaxBitrate: 1000,
              },

              appData: { kind: "video" },
            });
        }
        if (mediasoupInstance.videoProducer) {
          store.dispatch(setIsVideoProducerCreated(true));
          if (mediasoupInstance.videoProducer.rtpSender) {
            let videoParams =
              mediasoupInstance.videoProducer.rtpSender.getParameters();

            //@ts-ignore
            videoParams.encodings[0].priority = "high";
            videoParams.degradationPreference = "maintain-resolution";

            await mediasoupInstance.videoProducer.rtpSender.setParameters(
              videoParams
            );
          }

          // mediasoupInstance.videoProducer.pause();
          console.log(
            reconnectionReducer.isReconnectionStatusVideo,
            "video checking"
          );
          if (
            reconnectionReducer.isReconnectionStatusVideo
          ) {
            this.unpauseVideo(userId, undefined, true);
            store.dispatch(setIsReconnectionStatusVideo(false));
          }else{
            this.unpauseVideo(userId);
          }
          

        }
      }
    } catch (error) {
      console.error(error);
      // this.addErrorMessage(error.message);
      // Please make sure to handle this error
    }
  };

  connectLocalHark(track: MediaStreamTrack | null, userId: string | any) {
    console.log('connectLocalHark() [track:"%o"]', track);

    this._harkStream = new MediaStream();

    const newTrack = track?.clone();

    this._harkStream.addTrack(newTrack);

    if (newTrack) newTrack.enabled = true;

    this._hark = hark(this._harkStream, {
      play: false,
      interval: 100,
      threshold: -50,
      history: 100,
    });

    this._hark.lastVolume = -100;

    this._hark.on("volume_change", (volume: number) => {
      // Update only if there is a bigger diff
      if (
        mediasoupInstance.audioProducer &&
        Math.abs(volume - this._hark.lastVolume) > 0.5
      ) {
        // Decay calculation: keep in mind that volume range is -100 ... 0 (dB)
        // This makes decay volume fast if difference to last saved value is big
        // and slow for small changes. This prevents flickering volume indicator
        // at low levels
        if (volume < this._hark.lastVolume) {
          volume =
            this._hark.lastVolume -
            Math.pow(
              (volume - this._hark.lastVolume) / (100 + this._hark.lastVolume),
              2
            ) *
            10;
        }

        this._hark.lastVolume = volume;

        store.dispatch(setPeerVolume({ peerUserId: userId, volume }));
      }
    });

    this._hark.on("speaking", () => { });

    this._hark.on("stopped_speaking", () => {
      store.dispatch(setPeerVolume({ peerUserId: userId, volume: -100 }));
    });
  }

  disconnectLocalHark() {
    let privateCallReducer = store.getState().privateCall;

    if (privateCallReducer.imCaller) {
      socketInstance.sendMessage("PRIVATE_CALL_ENDED_ACKNOWLEDGE", {
        callEndAt: new Date(),
        callAction: "USER_DISCONNECTED",
      });
      store.dispatch(setCaller(false));
    }
    if (this._harkStream != null) {
      let [track] = this._harkStream.getAudioTracks();

      track.stop();
      track = null;

      this._harkStream = null;
    }
    console.log({ hark: this._hark })
    if (this._hark != null) this._hark.stop();
  }

  startAudioStream = async (activeAudioDevice: string) => {
    try {
      let reconnectionReducer = store.getState().reconnectionStatus;
      let { role, userId } = store.getState().user;
      if (mediasoupInstance.audioProducer) {
        mediasoupInstance.audioProducer.close();
      }

      if (activeAudioDevice !== "") {
        this.localAudioStream = await getAudioStream(activeAudioDevice);

        if (this.localAudioStream && mediasoupInstance.publishTransport) {
          const track = this.localAudioStream.getAudioTracks()[0].clone();

          const {
            opusStereo = false,
            opusDtx = true,
            opusFec = true,
            opusPtime = 20,
            opusMaxPlaybackRate = 96000,
          } = config.centralAudioOptions;

          mediasoupInstance.audioProducer =
            await mediasoupInstance.publishTransport.produce({
              track,
              zeroRtpOnPause: true,
              codecOptions: {
                opusStereo,
                opusDtx,
                opusFec,
                opusPtime,
                opusMaxPlaybackRate,
              },
              appData: { kind: "audio" },
            });

          mediasoupInstance.audioProducer.pause();
          store.dispatch(setIsAudioProducerCreated(true));
          console.log(
            reconnectionReducer.isReconnectionStatusAudio,
            "checking for video reconnection"
          );
          if (reconnectionReducer.isReconnectionStatusAudio) {
            this.unpauseLocalMic(userId);
            store.dispatch(setIsReconnectionStatusAudio(false));
          }
        }

        console.log("LOCAL", this.localAudioStream);
      }
    } catch (error) {
      console.error(error);
      // TODO AMIT
      // Please make sure to handle this error
    }
  };

  addStreamOfAudioFile = (selfUserId: string, elementId: string) => {
    let roomReducer = store.getState().room;
    // let roomId = roomReducer.currentRoomId;
    let roomName = roomReducer.currentRoom;
    // if (this.roomSocket.id === peerSessionId) {
    if (this.localAudioFileStream) {
      if (mediasoupInstance.audioFileProducer) {
        mediasoupInstance.audioFileProducer.close();
        const track = this.localAudioFileStream?.getAudioTracks()[0];
        track?.stop();
        socketInstance.sendMessage("AUDIO_STOP", {
          roomname: roomName,
        });
        this.localAudioFileStream = undefined;
        store.dispatch(setLocalAudioFilePaused(false))
        store.dispatch(setAudioFile(false));
      }
    } else {
      this.startAudioForFile(elementId, selfUserId);
    }
    //}
  };

  addStreamOfVideoFile = (peerSessionId: string) => {
    let roomReducer = store.getState().room;
    let localFileReducer = store.getState().localfilestreamstatus;
    let roomId = roomReducer.currentRoomId;
    let roomName = roomReducer.currentRoom;
    // if (this.roomSocket.id === peerSessionId) {
    if (this.localScreenFileStream) {

      if (mediasoupInstance.screenProducer) {

        mediasoupInstance.screenProducer.close();
        const track = this.localScreenFileStream?.getVideoTracks()[0];
        track?.stop();

        if (mediasoupInstance.screenAudioProducer) {

          mediasoupInstance.screenAudioProducer.close();
          const screenAudioTrack = this.localScreenStream?.getAudioTracks()[0];
          screenAudioTrack?.stop();
          store.dispatch(setIsScreenAudioShared(false));
        }

        // if (convertIntoBoolean(media.screenControl)) {
        //   socketInstance.sendMessage("CLOSE_SCREEN_SHARE_APP", {});
        //   if (!this.state.localScreenStoppedTwo) {
        //     this.shareUnshareScreenTwo(peerSessionId);
        //   }
        // }

        socketInstance.sendMessage("SCREEN_STOP", {
          roomname: roomName,
          roomId,
        });
        this.localScreenFileStream = undefined;
        store.dispatch(setLocalScreenFileStopped(true));

        if (!localFileReducer.isVideoFile) {

          this.startVideoForFile();
          this.addStreamOfAudioFile(peerSessionId, "videoFile");
        } else {
          store.dispatch(setVideoFile(false));
        }
      }
    } else {
      this.startVideoForFile();
      this.addStreamOfAudioFile(peerSessionId, "videoFile");
    }
    // }
  };

  startAudioForFile = async (elementId: string, peerUserId: string) => {
    try {
      let roomReducer = store.getState().room;
      let roomId = roomReducer.currentRoomId;
      let roomName = roomReducer.currentRoom;

      if (mediasoupInstance.audioFileProducer) {
        mediasoupInstance.audioFileProducer.close();
      }

      const audioEl = document.getElementById(elementId) as any;

      const localAudioFileStream = audioEl.captureStream();

      this.localAudioFileStream = localAudioFileStream;
      store.dispatch(setLocalAudioFilePaused(true));
      store.dispatch(setAudioFile(true));
      const track = localAudioFileStream.getAudioTracks()[0].clone();

      const {
        opusStereo = false,
        opusDtx = true,
        opusFec = true,
        opusPtime = 20,
        opusMaxPlaybackRate = 96000,
      } = config.centralAudioOptions;
      mediasoupInstance.audioFileProducer =
        await mediasoupInstance.publishTransport?.produce({
          track,
          zeroRtpOnPause: true,
          codecOptions: {
            opusStereo,
            opusDtx,
            opusFec,
            opusPtime,
            opusMaxPlaybackRate,
          },
          appData: { kind: "audio", audioNum: "File" },
        });

      // socketInstance.sendMessage("AUDIO_RESUME", {
      //   roomname: roomName,
      //   roomId: roomId,
      //   peerUserId,
      //   audioNum: "File",
      // });


    } catch (error) {
      console.error(error);
    }
  };

  startVideoForFile = async () => {
    try {
      if (mediasoupInstance.screenProducer) {
        mediasoupInstance.screenProducer.close();
      }

      if (mediasoupInstance.screenAudioProducer) {
        mediasoupInstance.screenAudioProducer.close();
        store.dispatch(setIsScreenAudioShared(false));
      }

      const videoEl = document.getElementById("videoFile") as any;

      const localScreenStream = videoEl.captureStream();
      const localScreenTrack = localScreenStream.getVideoTracks()[0];

      store.dispatch(screenTurnOrOff(false));
      this.localScreenFileStream = localScreenStream;
      store.dispatch(setVideoFile(true));
      //@ts-ignore
      // let encodings = this.screenSharingSimulcastEncoding.map((encoding) => ({
      //   ...encoding,
      //   dtx: true,
      // }));

      mediasoupInstance.screenProducer = await mediasoupInstance.publishTransport.produce({
        track: localScreenTrack,
        // encodings: undefined,
        codecOptions: {
          videoGoogleMaxBitrate: 1000,
        },
        codec: mediasoupInstance.device?.rtpCapabilities?.codecs?.find(
          (codec) => codec.mimeType.toLowerCase() === "video/vp8"
        ),
        appData: { kind: "screen" },
      });
    } catch (error: any) {
      console.error(error);
    }
  };

  startScreenStream = async (
    currentRoomId: string,
    userRole: string,
    remoteStreamEvent?: any
  ) => {
    try {
      let screenEncodings = this.getEncodingsForScreen();
      let roomReducer = store.getState().room;
      let reconnectionReducer = store.getState().reconnectionStatus;
      let meetingRoomReducer = store.getState().meetingRoom;
      console.log(screenEncodings);
      if (mediasoupInstance.screenProducer) {
        mediasoupInstance.screenProducer.close();
      }

      if (mediasoupInstance.screenAudioProducer) {
        mediasoupInstance.screenAudioProducer.close();
      }

      let screenStream;
      if (remoteStreamEvent) {
        screenStream = remoteStreamEvent.streams[0];
      } else {
        if (reconnectionReducer.isReconnectionStatusScreenOne) {
          screenStream = this.localScreenStream;
          store.dispatch(setIsReconnectionStatusScreenOne(false));
        } else {
          screenStream = await getScreenStream(
            screenResolution.SCREEN_SHARE_WIDTH,
            screenResolution.SCREEN_SHARE_HEIGHT
          );
        }
      }
      this.localScreenStream = screenStream;

      const localScreenTrack = screenStream.getVideoTracks()[0].clone();

      console.log(
        screenStream.getAudioTracks().length,
        "checking screen audio1"
      );
      // Create Screen Audio Producer
      if (screenStream.getAudioTracks().length) {
        const localScreenAudioTrack = screenStream.getAudioTracks()[0].clone();
        const {
          opusStereo = false,
          opusDtx = true,
          opusFec = true,
          opusPtime = 20,
          opusMaxPlaybackRate = 96000,
        } = config.centralAudioOptions;

        mediasoupInstance.screenAudioProducer =
          await mediasoupInstance.publishTransport?.produce({
            track: localScreenAudioTrack,
            zeroRtpOnPause: true,
            codecOptions: {
              opusStereo,
              opusDtx,
              opusFec,
              opusPtime,
              opusMaxPlaybackRate,
            },
            appData: { kind: "audio", audioNum: "System" },
          });

        store.dispatch(setIsScreenAudioShared(true));

        localScreenAudioTrack.onended = () => {
          socketInstance.sendMessage("SYSTEM_AUDIO_STOP", {
            roomId: currentRoomId,
          });
          localScreenAudioTrack.stop();

          if (mediasoupInstance.screenAudioProducer) {
            mediasoupInstance.screenAudioProducer.close();
          }
          store.dispatch(setIsScreenAudioShared(false));
        };
      }

      // Create a producer for screen
      mediasoupInstance.screenProducer =
        await mediasoupInstance.publishTransport?.produce({
          track: localScreenTrack,
          encodings: screenEncodings,
          codecOptions:
            userRole === role.AGENT
              ? { videoGoogleMaxBitrate: 1000 }
              : undefined,
          codec: mediasoupInstance.device?.rtpCapabilities?.codecs?.find(
            (codec) => codec.mimeType.toLowerCase() === "video/vp8"
          ),
          appData: { kind: "screen" },
        });

      localScreenTrack.onended = () => {
        socketInstance.sendMessage("SCREEN_STOP", { roomId: currentRoomId });
        localScreenTrack.stop();
        if (mediasoupInstance.screenProducer)
          mediasoupInstance.screenProducer.close();

        store.dispatch(screenTurnOrOff(true));
        if (roomReducer.viewMode === roomViewModeConst.collabMode) {
          if (!meetingRoomReducer.screenCollabPeerArray.length) {
            mediasoupInstance.scaleDownResolutionMethod(
              mediaTypeConstant.video,
              false,
              resolutionConstant.high
            );
          } else {
            mediasoupInstance.scaleDownResolutionMethod(
              mediaTypeConstant.video,
              false,
              resolutionConstant.low
            );
          }
        }
      };

      if (
        mediasoupInstance.screenProducer &&
        mediasoupInstance.screenProducer.rtpSender
      ) {
        let screenParams =
          mediasoupInstance.screenProducer.rtpSender.getParameters();
        screenParams.encodings[0].priority = "high";
        screenParams.degradationPreference = "maintain-resolution";
        await mediasoupInstance.screenProducer.rtpSender.setParameters(
          screenParams
        );
      }

      store.dispatch(screenTurnOrOff(false));
    } catch (error) {
      console.error(error);
      // Handle the error appropriately
    }
  };

  startScreenTwo = async (
    currentRoomId: string,
    userRole: string,
    remoteStreamEvent?: any
  ) => {
    try {
      let screenEncodings = this.getEncodingsForScreen();
      let reconnectionReducer = store.getState().reconnectionStatus;
      if (mediasoupInstance.screenTwoProducer) {
        mediasoupInstance.screenTwoProducer.close();
      }

      let localScreenTwoStream;
      if (remoteStreamEvent) {
        localScreenTwoStream = remoteStreamEvent.streams[0];
      } else {

        if (reconnectionReducer.isReconnectionStatusScreenTwo) {
          localScreenTwoStream = this.localSecondScreenStream;
          store.dispatch(setIsReconnectionStatusScreenTwo(false))
        } else {
          localScreenTwoStream = await getScreenStream(
            screenResolution.SCREEN_SHARE_WIDTH,
            screenResolution.SCREEN_SHARE_HEIGHT
          );
        }
      }

      this.localSecondScreenStream = localScreenTwoStream;
      const localScreenTwoTrack = localScreenTwoStream
        .getVideoTracks()[0]
        .clone();

      mediasoupInstance.screenTwoProducer =
        await mediasoupInstance.publishTransport?.produce({
          track: localScreenTwoTrack,
          encodings: screenEncodings,
          codecOptions:
            userRole === role.AGENT
              ? { videoGoogleMaxBitrate: 1000 }
              : undefined,
          codec: mediasoupInstance.device?.rtpCapabilities?.codecs?.find(
            (codec) => codec.mimeType.toLowerCase() === "video/vp8"
          ),
          appData: { kind: "screen", screenNum: "Two" },
        });

      localScreenTwoTrack.onended = () => {
        socketInstance.sendMessage("SCREEN_STOP", {
          roomId: currentRoomId,
          screenNum: "Two",
        });
        localScreenTwoTrack.stop();
        if (mediasoupInstance.screenTwoProducer)
          mediasoupInstance.screenTwoProducer.close();
        store.dispatch(screenTwoTurnOrOff(true));
      };

      if (
        mediasoupInstance.screenTwoProducer &&
        mediasoupInstance.screenTwoProducer.rtpSender
      ) {
        let screenTwoParams =
          mediasoupInstance.screenTwoProducer.rtpSender.getParameters();

        screenTwoParams.encodings[0].priority = "high";
        screenTwoParams.degradationPreference = "maintain-resolution";

        await mediasoupInstance.screenTwoProducer.rtpSender.setParameters(
          screenTwoParams
        );
      }
      store.dispatch(screenTwoTurnOrOff(false));
    } catch (error: any) {
      console.error(error);
      // TODO JAGDEEP
      // Please make sure to handle this error
    }
  };

  consumeAudioStream = async (peer: RemotePeerData) => {
    try {
      let roomReducer = store.getState().room;
      let roomId = roomReducer.currentRoomId;
      let roomName = roomReducer.currentRoom;
      let peerData:any = {};
      let troubleshoot = store.getState().troubleshoot;
      if (
        (mediasoupInstance.device && !mediasoupInstance.device.loaded) ||
        !mediasoupInstance.device
      ) {
        // await this.getRouterRtpCaps();
        console.log("-----------audio----------", "device not found");
        await constantMethod.sleep(5000);
      }
      const rtpCapabilities = mediasoupInstance.device?.rtpCapabilities;
      const remoteAudioStream = new MediaStream();
      const remoteAudioStreamData = (await socketInstance.sendMessage(
        "AUDIO_CONSUME",
        {
          roomname: roomName,
          roomId,
          peerToBeConsumedUserId: peer.peerUserId,
          rtpCapabilities,
        }
      )) as any;
      const { audioConsumerObj } = remoteAudioStreamData;
      if (audioConsumerObj && mediasoupInstance.subscribeTransport) {
        const { id, producerId, kind, rtpParameters } = audioConsumerObj;
        const audioConsumer: any =
        await mediasoupInstance.subscribeTransport.consume({
          id,
          producerId,
          kind,
          rtpParameters,
        });
        
        if (troubleshoot && rtpParameters.rtcp.cname) {
          watchRTC.mapStream(rtpParameters.rtcp.cname, peer.peerUsername);
        }
        remoteAudioStream.addTrack(audioConsumer.track);
        
        if (
          !store.getState().media.remotePeers[peer.peerUserId].peerAudioPaused || !audioConsumerObj.producerPaused
        ) {
          peerData.peerAudioPaused = false;
        }
        if (this.peerRemoteStream) {
          this.peerRemoteStream[peer.peerUserId] = {
            ...this.peerRemoteStream[peer.peerUserId],
            audioStream: remoteAudioStream,
            audioConsumer,
          };
        }

        audioConsumer.volume = 0;

        const stream = new MediaStream();

        stream.addTrack(audioConsumer.track);

        if (!stream.getAudioTracks()[0]) {
          throw new Error(
            "request.newConsumer | given stream has no audio track"
          );
        }

        audioConsumer.hark = hark(stream, { play: false });

        audioConsumer.hark.on("volume_change", (volume: number) => {
          volume = Math.round(volume);

          if (audioConsumer && volume !== audioConsumer.volume) {
            audioConsumer.volume = volume;

            store.dispatch(
              setPeerVolume({
                peerUserId: peer.peerUserId,
                volume,
              })
            );
            // this.props.setPeerVolume(peer.peerSessionId, volume);
          }
        });
        store.dispatch(
          updateRemotePeer({
            peerUserId: peer.peerUserId,
            remoteUserData: peerData,
          })
        );
      }
    } catch (e) {
      toast(
        "Cannot Consume Audio Stream of " + peer.peerUsername + " Properly",
        {
          icon: "❌",
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        }
      );
    }
  };

  consumeAudioFileStream = async (peer: RemotePeerData) => {
    try {
      let roomReducer = store.getState().room;
      let roomId = roomReducer.currentRoomId;
      let roomName = roomReducer.currentRoom;
      let peerData: any = {};
      if (
        (mediasoupInstance.device && !mediasoupInstance.device.loaded) ||
        !mediasoupInstance.device
      ) {
        // await this.getRouterRtpCaps();
        console.log("-----------audio----------", "device not found");
        await constantMethod.sleep(5000);
      }
      const rtpCapabilities = mediasoupInstance.device?.rtpCapabilities;
      const remoteAudioStream = new MediaStream();
      const remoteAudioStreamData = (await socketInstance.sendMessage(
        "AUDIO_CONSUME",
        {
          roomname: roomName,
          roomId,
          peerToBeConsumedUserId: peer.peerUserId,
          rtpCapabilities,
          audioNum: "File",
        }
      )) as any;
      const { audioConsumerObj } = remoteAudioStreamData;
      if (audioConsumerObj && mediasoupInstance.subscribeTransport) {
        if (audioConsumerObj.producerPaused) {
          peerData.peerAudioFilePaused = true;
        }
        const { id, producerId, kind, rtpParameters } = audioConsumerObj;
        const audioConsumer: any =
          await mediasoupInstance.subscribeTransport.consume({
            id,
            producerId,
            kind,
            rtpParameters,
          });
        remoteAudioStream.addTrack(audioConsumer.track);
        if (this.peerRemoteStream) {
          this.peerRemoteStream[peer.peerUserId].audioFileStream =
            remoteAudioStream;
          this.peerRemoteStream[peer.peerUserId].audioFileConsumer =
            audioConsumer;
        }

        // audioConsumer.volume = 0;

        // const stream = new MediaStream();

        // stream.addTrack(audioConsumer.track);

        // if (!stream.getAudioTracks()[0]) {
        //   throw new Error(
        //     "request.newConsumer | given stream has no audio track"
        //   );
        // }

        // audioConsumer.hark = hark(stream, { play: false });

        // audioConsumer.hark.on("volume_change", (volume: number) => {
        //   volume = Math.round(volume);

        //   if (audioConsumer && volume !== audioConsumer.volume) {
        //     audioConsumer.volume = volume;

        //     this.props.setPeerVolume(peer.peerSessionId, volume);
        //   }
        // });
        store.dispatch(
          updateRemotePeer({
            peerUserId: peer.peerUserId,
            remoteUserData: peerData,
          })
        );
        // return peer;
      }
    } catch (e) {
      toast(
        "Cannot Consume Audio File Stream of " +
        peer.peerUsername +
        " Properly",
        {
          icon: "❌",
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        }
      );
    }
  };

  consumeVideoStream = async (peer: RemotePeerData) => {
    try {
      let roomReducer = store.getState().room;
      let roomId = roomReducer.currentRoomId;
      let roomName = roomReducer.currentRoom;
      let peerData: any = {};
      let troubleshoot = store.getState().troubleshoot;

      if (
        (mediasoupInstance.device && !mediasoupInstance.device.loaded) ||
        !mediasoupInstance.device
      ) {
        // await this.getRouterRtpCaps();
        console.log("-----------video----------", "device not found");
        await constantMethod.sleep(5000);
      }
      const rtpCapabilities = mediasoupInstance.device?.rtpCapabilities;
      const remoteVideoStream = new MediaStream();
      const remoteVideoStreamData = (await socketInstance.sendMessage(
        "VIDEO_CONSUME",
        {
          roomname: roomName,
          roomId,
          peerToBeConsumedUserId: peer.peerUserId,
          rtpCapabilities,
        }
      )) as any;
      const { videoConsumerObj } = remoteVideoStreamData;
      if (videoConsumerObj && mediasoupInstance.subscribeTransport) {
        const { id, producerId, kind, rtpParameters } = videoConsumerObj;
        const videoConsumer =
          await mediasoupInstance.subscribeTransport.consume({
            id,
            producerId,
            kind,
            rtpParameters,
          });

        if (troubleshoot && rtpParameters.rtcp.cname) {
          watchRTC.mapStream(rtpParameters.rtcp.cname, peer.peerUsername);
        }

        remoteVideoStream.addTrack(videoConsumer.track);
        let peerObj = {
          ...store.getState().media.remotePeers[peer.peerUserId],
        };
        if (!videoConsumerObj.producerPaused || !peerObj.peerVideoPaused) {
          peerData.peerVideoPaused = false;
        }

        if (this.peerRemoteStream) {
          this.peerRemoteStream[peer.peerUserId] = {
            ...this.peerRemoteStream[peer.peerUserId],
            videoStream: remoteVideoStream,
          };
        }

        store.dispatch(
          updateRemotePeer({
            peerUserId: peer.peerUserId,
            remoteUserData: peerData,
          })
        );
      }
    } catch (e) {
      console.log(e);
      toast(
        "Cannot Consume video Stream of " + peer.peerUsername + " Properly",
        {
          icon: "❌",
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        }
      );
    }
  };

  consumeScreenStream = async (peer: RemotePeerData) => {
    try {
      if (peer) {
        let roomReducer = store.getState().room;
        let roomId = roomReducer.currentRoomId;
        let roomName = roomReducer.currentRoom;
        let peerData: any = {};
        if (
          (mediasoupInstance.device && !mediasoupInstance.device.loaded) ||
          !mediasoupInstance.device
        ) {
          // await this.getRouterRtpCaps();
          console.log("-----------screen----------", "device not found");
          await constantMethod.sleep(5000);
        }
        const rtpCapabilities = mediasoupInstance.device?.rtpCapabilities;
        const remoteScreenStream = new MediaStream();
        const remoteScreenStreamData = (await socketInstance.sendMessage(
          "SCREEN_CONSUME",
          {
            roomname: roomName,
            roomId,
            peerToBeConsumedUserId: peer.peerUserId,
            rtpCapabilities,
          }
        )) as any;
        const { screenConsumerObj } = remoteScreenStreamData;
        if (screenConsumerObj && mediasoupInstance.subscribeTransport) {
          peerData.peerScreenStopped = false;
          if (screenConsumerObj.producerPaused) {
            peerData.peerScreenStopped = true;
          }
          const { id, producerId, kind, rtpParameters } = screenConsumerObj;
          const screenConsumer =
            await mediasoupInstance.subscribeTransport.consume({
              id,
              producerId,
              kind,
              rtpParameters,
            });
          remoteScreenStream.addTrack(screenConsumer.track);
          if (this.peerRemoteStream) {
            this.peerRemoteStream[peer.peerUserId] = {
              ...this.peerRemoteStream[peer.peerUserId],
              screenStream: remoteScreenStream,
            };
              
          }
          store.dispatch(
            updateRemotePeer({
              peerUserId: peer.peerUserId,
              remoteUserData: peerData,
            })
          );
        }
      }
    } catch (e) {
      console.log("error aaagi:", e);
      toast(
        "Cannot Consume Screen Stream of " + peer.peerUsername + " Properly",
        {
          icon: "❌",
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        }
      );
    }
  };

  consumeScreenTwoStream = async (peer: RemotePeerData) => {
    try {
      let roomReducer = store.getState().room;
      let roomId = roomReducer.currentRoomId;
      let roomName = roomReducer.currentRoom;
      let peerData: any = {};
      if (
        (mediasoupInstance.device && !mediasoupInstance.device.loaded) ||
        !mediasoupInstance.device
      ) {
        // await this.getRouterRtpCaps();
        console.log("-----------screenTwo-----------", "device not found");
        await constantMethod.sleep(5000);
      }
      const rtpCapabilities = mediasoupInstance.device?.rtpCapabilities;
      const remoteScreenStream = new MediaStream();
      const remoteScreenStreamData = (await socketInstance.sendMessage(
        "SCREEN_CONSUME",
        {
          roomname: roomName,
          roomId,
          peerToBeConsumedUserId: peer.peerUserId,
          screenNum: "Two",
          rtpCapabilities,
        }
      )) as any;
      const { screenConsumerObj } = remoteScreenStreamData;
      if (screenConsumerObj && mediasoupInstance.subscribeTransport) {
        peerData.peerscreenTwoStopped = false;
        if (screenConsumerObj.producerPaused) {
          peerData.peerscreenTwoStopped = true;
        }
        const { id, producerId, kind, rtpParameters } = screenConsumerObj;
        const screenConsumer =
          await mediasoupInstance.subscribeTransport.consume({
            id,
            producerId,
            kind,
            rtpParameters,
          });
        remoteScreenStream.addTrack(screenConsumer.track);
        if (this.peerRemoteStream) {
          this.peerRemoteStream[peer.peerUserId] =
          {
            ...this.peerRemoteStream[peer.peerUserId],
            screenTwoStream: remoteScreenStream,
          };
        }

        store.dispatch(
          updateRemotePeer({
            peerUserId: peer.peerUserId,
            remoteUserData: peerData,
          })
        );
      }
    } catch (e) {
      toast(
        "Cannot Consume Screen Stream of " + peer.peerUsername + " Properly",
        {
          icon: "❌",
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        }
      );
    }
  };

  consumeSystemAudioStream = async (peer: RemotePeerData) => {
    try {
      let roomReducer = store.getState().room;
      let roomId = roomReducer.currentRoomId;
      let roomName = roomReducer.currentRoom;
      let peerData: any = {};
      if (
        (mediasoupInstance.device && !mediasoupInstance.device.loaded) ||
        !mediasoupInstance.device
      ) {
        // await this.getRouterRtpCaps();
        console.log("-----------screen audio----------", "device not found");
        await sleep(5000);
      }
      const rtpCapabilities = mediasoupInstance.device?.rtpCapabilities;
      const remoteAudioStream = new MediaStream();
      const remoteAudioStreamData = (await socketInstance.sendMessage(
        "SCREEN_AUDIO_CONSUME",
        {
          roomname: roomName,
          roomId,
          peerToBeConsumedUserId: peer.peerUserId,
          rtpCapabilities,
          audioNum: "System",
        }
      )) as any;
      const { audioConsumerObj } = remoteAudioStreamData;
      if (audioConsumerObj) {
        if (audioConsumerObj.producerPaused) {
          peerData.screenAudioPaused = true;
        }
        const { id, producerId, kind, rtpParameters } = audioConsumerObj;
        const audioConsumer: any =
          await mediasoupInstance.subscribeTransport?.consume({
            id,
            producerId,
            kind,
            rtpParameters,
          });
        remoteAudioStream.addTrack(audioConsumer.track);
        if (this.peerRemoteStream) {
          this.peerRemoteStream[peer.peerUserId].screenAudioStream =
            remoteAudioStream;
          this.peerRemoteStream[peer.peerUserId].systemAudioConsumer =
            audioConsumer;
        }
        // this.addPeerToArrayIfDoesNotExists(peer);

        audioConsumer.volume = 0;

        const stream = new MediaStream();

        stream.addTrack(audioConsumer.track);

        if (!stream.getAudioTracks()[0]) {
          throw new Error(
            "request.newConsumer | given stream has no audio track"
          );
        }

        // audioConsumer.hark = hark(stream, { play: false });

        // audioConsumer.hark.on("volume_change", (volume: number) => {
        //   volume = Math.round(volume);

        //   if (audioConsumer && volume !== audioConsumer.volume) {
        //     audioConsumer.volume = volume;

        //     this.props.setPeerVolume(peer.peerSessionId, volume);
        //   }
        // });

        store.dispatch(
          updateRemotePeer({
            peerUserId: peer.peerUserId,
            remoteUserData: peerData,
          })
        );
      } else {
        return;
      }
    } catch (e) {
      toast(
        "Cannot Consume Screen Audio Stream of " +
        peer.peerUsername +
        " Properly",
        {
          icon: "❌",
          style: { borderRadius: "10px", background: "#333", color: "#fff" },
        }
      );
    }
  };

  replaceVideoStream = async () => {
    let { videoOption } = store.getState().videoOption;
    let { activeVideoDevice } = store.getState().device;
    let environments = store.getState().environment.environments;
    let { cameraBackground } = store.getState().user;
    let customBgImg = environments.VIDEO_BACKGROUND_IMAGES
      ? environments.VIDEO_BACKGROUND_IMAGES.status
      : true;

    let blurOption = environments.VIDEO_BACKGROUND_BLUR
      ? environments.VIDEO_BACKGROUND_BLUR.status
      : true;
    console.log("replaceVideoStream", { videoOption, cameraBackground, blurOption, selec: this.selectedTflite })


    if (
      (videoOption === "blur" || videoOption === "background") &&
      this.selectedTflite
    ) {
      store.dispatch(videoTurnOrOff(false));
      const node = document.getElementById("blur");
      // @ts-ignore
      const track = node?.captureStream()?.getVideoTracks()[0];
      if (track) mediasoupInstance.replaceVideoTrack(track);
    } else {
      const videoStream = await getVideoStream(activeVideoDevice);
      this.localVideoStream = videoStream;
      if (
        // custom image
        cameraBackground.backgroundType === "background" &&
        this.selectedTflite &&
        customBgImg
      ) {
        store.dispatch(setImageLink(cameraBackground.url));
        store.dispatch(setVideoOption(cameraBackground.backgroundType));
      } else if (
        this.selectedTflite &&
        cameraBackground.backgroundType === "blur" &&
        blurOption
      ) {
        // blur
        store.dispatch(setVideoOption(cameraBackground.backgroundType));
      } else {
        // normal
        store.dispatch(setVideoOption("normal"));
      }

      if (mediasoupInstance.videoProducer) {
        if (this.videoTrack) {
          this.videoTrack.stop()
          this.videoTrack = undefined
        }
        this.videoTrack = videoStream?.getVideoTracks()[0].clone();
        mediasoupInstance.videoProducer.replaceTrack({
          track: videoStream?.getVideoTracks()[0].clone(),
        });
      }
      store.dispatch(videoTurnOrOff(false));
    }
  };

  resumeConsumersForPeer = async (
    peerUserIdsArray: string[],
    collabPeerId = ""
  ) => {
    let roomReducer = store.getState().room;
    let roomId = roomReducer.currentRoomId;
    let roomName = roomReducer.currentRoom;

    let collabScreenPeerId = "";
    let peerOnAnotherPage = false;
    if (roomReducer.viewMode === roomViewModeConst.collabMode) {
      let meetingRoomReducer = store.getState().meetingRoom;
      console.log(
        meetingRoomReducer.screenCollabPeer.peerId,
        meetingRoomReducer
      );
      // getting scrreen peer id from redux for collab mode
      collabScreenPeerId =
        collabPeerId !== ""
          ? collabPeerId
          : meetingRoomReducer.screenCollabPeer.peerId;

      // // checking if peer on second page who sharing screen so than consume particular screen peer by everyone
      console.log(collabScreenPeerId);
      if (collabScreenPeerId !== "") {
        peerOnAnotherPage = !peerUserIdsArray.includes(collabScreenPeerId);
      }
    }

    await socketInstance.sendMessage("RESUME_CONSUMERS_FOR_PEER", {
      roomname: roomName,
      roomId: roomId,
      consumersToBeResumed: peerUserIdsArray,
      collabScreenPeerId,
      peerOnAnotherPage,
    });
  };

  pauseConsumersForPeer = async (peerUserIdsArray: string[]) => {
    let roomReducer = store.getState().room;

    await socketInstance.sendMessage("PAUSE_CONSUMERS_FOR_PEER", {
      roomname: roomReducer.currentRoom,
      consumersToBePaused: peerUserIdsArray,
    });
  };

  unpauseVideo = (peerUserId: string, tflite?:any, doFireAlert:boolean = false) => {
    const { videoPauseAlertInterval } = store.getState().media;
    let roomReducer = store.getState().room;
    let chatReducer = store.getState().chat;
    let mediaReducer = store.getState().media;
    let environmentReducer = store.getState().environment;
    let { userId, role } = store.getState().user;
    const isCameraNotificationsEnabled = environmentReducer.environments.REACT_APP_CAMERA_OFF_NOTIFICATIONS
    ? environmentReducer.environments.REACT_APP_CAMERA_OFF_NOTIFICATIONS.status
    : REACT_APP_CAMERA_OFF_NOTIFICATIONS;

    const agentCamAutoTurnOn = environmentReducer.environments.AGENT_CAM_AUTO_ON
    ? environmentReducer.environments.AGENT_CAM_AUTO_ON.status
    : AGENT_CAM_AUTO_ON;

    const agentCamAlwaysOn = environmentReducer.environments.AGENT_CAM_ALWAYS_ON
    ? environmentReducer.environments.AGENT_CAM_ALWAYS_ON.status
    : AGENT_CAM_ALWAYS_ON;

    if (isCameraNotificationsEnabled && videoPauseAlertInterval) {
      store.dispatch(clearVideoPauseAlertInterval());
    } else if (isCameraNotificationsEnabled && !constantMethod.checkHigherRole(role)) {
      const shouldNotFireEvent =
      (chatReducer.emojiIconMain && 
        ['BRB', 'Break', 'Lunch'].includes(chatReducer.emojiIconMain)) ||
      (mediaReducer.fireAlertForAgenCam && (agentCamAlwaysOn || agentCamAutoTurnOn)) ||
      doFireAlert;
      if (shouldNotFireEvent) {
        console.log("No event to be fired in this");
        store.dispatch(clearVideoPauseAlertInterval());
        store.dispatch(setFireAlertForCam());
      } else {
        socketInstance.sendMessage("GET_ALERT_NOTIFICATION_FOR_CAMERA_ON_OFF", {
          msg: "ON",
          peerUserId: peerUserId,
        });
      }
    }

    if (userId === peerUserId) {
      // store.dispatch(videoTurnOrOff(false));
      mediasoupInstance.videoProducer?.resume();
      replaceVideoTimeout = setTimeout(() => {
        this.replaceVideoStream();
      }, 200);
      socketInstance.sendMessage("VIDEO_RESUME", {
        roomname: roomReducer.currentRoom,
        peerUserId: userId,
      });
    } else {
      const peer = { ...store.getState().media.remotePeers[peerUserId] };
      const peerData: any = {};
      if (peer) {
        if (peer.peerVideoPaused) {
          socketInstance.sendMessage("VIDEO_RESUME", {
            roomId: roomReducer.currentRoomId,
            peerUserId,
          });
          peerData.peerVideoPaused = false;

          store.dispatch(
            updateRemotePeer({
              peerUserId: peer.peerUserId,
              remoteUserData: peerData,
            })
          );
        }
      }
    }
  };

  pauseVideo = (peerUserId: string) => {
    let roomReducer = store.getState().room;
    let environmentReducer = store.getState().environment;
    let { userId, role } = store.getState().user;
    const isCameraNotificationsEnabled = environmentReducer.environments.REACT_APP_CAMERA_OFF_NOTIFICATIONS
    ? environmentReducer.environments.REACT_APP_CAMERA_OFF_NOTIFICATIONS.status
    : REACT_APP_CAMERA_OFF_NOTIFICATIONS;

    const cameraNotificationsTimerValue = environmentReducer.environments.REACT_APP_CAMERA_OFF_NOTIFICATIONS
    ? parseInt(environmentReducer.environments.REACT_APP_CAMERA_OFF_NOTIFICATIONS.value)
    : CAMERA_NOTIFICATION_TIMER_INTERVAL;


    if (isCameraNotificationsEnabled && !constantMethod.checkHigherRole(role)) {
      // Set a new timeout and store it in Redux
      const newInterval = setTimeout(() => {
        socketInstance.sendMessage("GET_ALERT_NOTIFICATION_FOR_CAMERA_ON_OFF", {
          msg: "OFF",
          peerUserId: peerUserId
        });
        toast.success("Your Camera is Off");
        notifyMe("Your Camera is Off");

        store.dispatch(clearVideoPauseAlertInterval());
      }, Number(cameraNotificationsTimerValue * 60 * 1000));

      store.dispatch(setVideoPauseAlertInterval(newInterval));
    }

    if (userId === peerUserId) {
      store.dispatch(videoTurnOrOff(true));
      mediasoupInstance.videoProducer?.pause();
      if (this.localVideoStream) {

        this.localVideoStream.getVideoTracks().forEach(function (track: any) {
          track.stop();
        });
        this.localVideoStream = null;
      }

      // this.localVideoStream = null;
      store.dispatch(setVideoOption("normal"));
      store.dispatch(setIsVirtualModalOpen(false));
      store.dispatch(setImageLink(""));

      socketInstance.sendMessage("VIDEO_PAUSE", {
        roomname: roomReducer.currentRoom,
        peerUserId: userId,
      });

    } else {
      const peer = { ...store.getState().media.remotePeers[peerUserId] };
      let peerData: any = {};
      if (peer) {
        if (!peer.peerVideoPaused) {
          socketInstance.sendMessage("VIDEO_PAUSE", {
            roomId: roomReducer.currentRoomId,
            peerUserId,
          });

          peerData.peerVideoPaused = true;
          peerData.numfaces = "";
          peerData.expression = ""
          peerData.faceVerificationSuccess = false;
          peerData.isNotSamePerson = false;
          peerData.showAlertInParticipant = false;
          store.dispatch(
            updateRemotePeer({
              peerUserId: peer.peerUserId,
              remoteUserData: peerData,
            })
          );
          store.dispatch(clearVerifyFace());
        }
      }
    }
  };

  unpauseLocalMic = (peerUserId: string) => {
    try {
      let roomReducer = store.getState().room;
      let mediaReducer = store.getState().media;
      let { userId } = store.getState().user;
      let observeReducer = store.getState().observe;

      if (userId === peerUserId) {
        if (
          mediasoupInstance.audioProducer &&
          mediaReducer.localAudioPaused === true
        ) {
          if (observeReducer.openentObserverAudioId !== "") {
            socketInstance.sendMessage("STOP_OBSERVE_AUDIO_AGENT", {
              roomname: roomReducer.currentRoom,
              userId: observeReducer.openentObserverAudioId,
              from: "unmuteagent",
            });
          }

          mediasoupInstance.audioProducer.resume();
          store.dispatch(audioTurnOrOff(false));

          socketInstance.sendMessage("AUDIO_RESUME", {
            roomname: roomReducer.currentRoom,
            roomId: roomReducer.currentRoomId,
            peerUserId,
          });
          const track = mediasoupInstance.audioProducer.track;
          this.connectLocalHark(track, userId);
        }
      } else {
        const peer = { ...store.getState().media.remotePeers[peerUserId] };
        let peerData: any = {};
        if (peer) {
          if (peer.peerAudioPaused) {
            socketInstance.sendMessage("AUDIO_RESUME", {
              roomId: roomReducer.currentRoomId,
              peerUserId,
            });
            peerData.peerAudioPaused = false;

            store.dispatch(
              updateRemotePeer({
                peerUserId: peer.peerUserId,
                remoteUserData: peerData,
              })
            );
          }
        }
      }
    } catch (error) {
      console.error("cannot UnPause local mic :" + error);
    }
  };

  pauseLocalMic = (peerUserId: string) => {
    try {
      let roomReducer = store.getState().room;
      let mediaReducer = store.getState().media;
      let { userId } = store.getState().user;
      if (userId === peerUserId) {
        if (
          mediasoupInstance.audioProducer &&
          mediaReducer.localAudioPaused === false
        ) {
          mediasoupInstance.audioProducer.pause();
          store.dispatch(audioTurnOrOff(true));
          socketInstance.sendMessage("AUDIO_PAUSE", {
            roomname: roomReducer.currentRoom,
            roomId: roomReducer.currentRoomId,
            peerUserId,
          });
          this.disconnectLocalHark();
        }
      } else {
        const peer = { ...store.getState().media.remotePeers[peerUserId] };
        let peerData: any = {};
        if (peer) {
          if (!peer.peerAudioPaused) {
            socketInstance.sendMessage("AUDIO_PAUSE", {
              roomId: roomReducer.currentRoomId,
              peerUserId,
            });

            peerData.peerAudioPaused = true;
            store.dispatch(
              updateRemotePeer({
                peerUserId: peer.peerUserId,
                remoteUserData: peerData,
              })
            );
          }
        }
      }
    } catch (e) {
      console.error("cannot pause local mic :" + e);
    }
  };

  shareScreen = (peerUserId: string) => {
    let roomReducer = store.getState().room;
    let user = store.getState().user;
    let mediaReducer = store.getState().media;
    let { environments } = store.getState().environment;
    let { isReconnectionStatusScreenForElectron } = store.getState().reconnectionStatus;
    const screenControl = environments.REACT_APP_SCREENCONTROL
      ? environments.REACT_APP_SCREENCONTROL.status
      : false;
    let { userId, role } = store.getState().user;
    if (peerUserId === userId) {
      if (convertIntoBoolean(screenControl) || user.enableDesktopApp) {
        // this.openElectronApp();
        if (this.intervalForElectronScreenControl) {
          clearInterval(this.intervalForElectronScreenControl);
        }
        if (!constantMethod.checkHigherRole(user.role)) {
          socketInstanceDesktopApp.sendMessage("IS_ELECTRON_CONNECTED", {
            reconnect: false,
          });
          store.dispatch(setIsReconnectionStatusScreenForElectron(false))

        } else {
          this.startScreenStream(roomReducer.currentRoomId, role);
        }
      } else {
        this.startScreenStream(roomReducer.currentRoomId, role);
      }
    } else {
      socketInstance.sendMessage("START_SCREEN_SHARE", {
        roomname: roomReducer.currentRoom,
        peerUserId,
      });
    }
  };

  unshareScreen = (peerUserId: string) => {
    let roomReducer = store.getState().room;
    let mediaReducer = store.getState().media;
    let { userId, role, enableDesktopApp } = store.getState().user;
    let { screenCollabPeerArray } = store.getState().meetingRoom;
    let { environments } = store.getState().environment;
    const screenControl = environments.REACT_APP_SCREENCONTROL
      ? environments.REACT_APP_SCREENCONTROL.status
      : false;

    if (peerUserId === userId) {
      if (mediasoupInstance.screenProducer) {
        mediasoupInstance.screenProducer.close();
        const track = this.localScreenStream?.getVideoTracks()[0];
        if (track) {
          track?.stop();
        }
        if (convertIntoBoolean(screenControl) || enableDesktopApp) {
          if (!constantMethod.checkHigherRole(role)) {
            socketInstanceDesktopApp.sendMessage("APP_CLOSE_SCREEN_SHARE", {});
            if (!mediaReducer.localScreenStoppedTwo) {
              this.unshareScreenTwo(peerUserId);
            }
          }
        }
        socketInstance.sendMessage("SCREEN_STOP", {
          roomId: roomReducer.currentRoomId,
          peerUserId
        });


        this.localScreenStream = undefined;
        store.dispatch(screenTurnOrOff(true));

        if (mediasoupInstance.screenAudioProducer) {
          mediasoupInstance.screenAudioProducer.close();
          const trackScreenAudio =
            // @ts-ignore
            this.localScreenStream?.getAudioTracks()[0];
          trackScreenAudio?.stop();
          socketInstance.sendMessage("SYSTEM_AUDIO_STOP", {
            roomname: roomReducer.currentRoom,
            roomId: roomReducer.currentRoomId,
          });
          store.dispatch(setIsScreenAudioShared(false));
        }
        if (roomReducer.viewMode === roomViewModeConst.collabMode) {
          if (!screenCollabPeerArray.length) {
            mediasoupInstance.scaleDownResolutionMethod(
              mediaTypeConstant.video,
              false,
              resolutionConstant.high
            );
          } else {
            mediasoupInstance.scaleDownResolutionMethod(
              mediaTypeConstant.video,
              false,
              resolutionConstant.low
            );
          }
        }
      }
    } else {
      socketInstance.sendMessage("STOP_SCREEN_SHARE", {
        roomname: roomReducer.currentRoom,
        peerUserId,
      });
    }
  };

  shareScreenTwo = (peerUserId: string) => {
    let roomReducer = store.getState().room;
    let mediaReducer = store.getState().media;
    let { userId, role } = store.getState().user;

    if (peerUserId === userId) {
      this.startScreenTwo(roomReducer.currentRoomId, role);
    } else {
      socketInstance.sendMessage("START_SCREEN_SHARE", {
        roomname: roomReducer.currentRoom,
        peerUserId,
        screenNum: "Two",
      });
    }
  };

  unshareScreenTwo = (peerUserId: string) => {
    let roomReducer = store.getState().room;
    let mediaReducer = store.getState().media;
    let { userId } = store.getState().user;

    if (peerUserId === userId) {
      if (mediasoupInstance.screenTwoProducer) {
        mediasoupInstance.screenTwoProducer.close();
        const track = this.localSecondScreenStream?.getVideoTracks()[0];
        if (track) {
          track?.stop();
        }
        socketInstance.sendMessage("SCREEN_STOP", {
          roomId: roomReducer.currentRoomId,
          screenNum: "Two",
          peerUserId
        });

        this.localSecondScreenStream = undefined;
        store.dispatch(screenTwoTurnOrOff(true));
      }
    } else {
      socketInstance.sendMessage("STOP_SCREEN_SHARE", {
        roomname: roomReducer.currentRoom,
        peerUserId,
        screenNum: "Two",
      });
    }
  };

  combineSystemAndMicAudioTracks = (
    systemAudioTrack: MediaStreamTrack,
    micAudioTrack: MediaStreamTrack
  ): MediaStreamTrack => {
    var systemAudioStream = new MediaStream();
    systemAudioStream.addTrack(systemAudioTrack);

    var micAudioStream = new MediaStream();
    micAudioStream.addTrack(micAudioTrack);

    const audioContext = new AudioContext();

    const systemAudioIn =
      audioContext.createMediaStreamSource(systemAudioStream);
    const micAudioIn = audioContext.createMediaStreamSource(micAudioStream);

    const dest = audioContext.createMediaStreamDestination();

    systemAudioIn.connect(dest);
    micAudioIn.connect(dest);

    const finalStream = dest.stream;

    return finalStream.getAudioTracks()[0];
  };

  processAudioOfAgent = () => {
    //  setTimeout(() => {

    if (this.localAudioStream && this.systemAudioFromElectron) {
      const micAudioTrack = this.localAudioStream.getAudioTracks()[0];
      const systemAudioTrack = this.systemAudioFromElectron.track;

      const combinedAudioTrack = this.combineSystemAndMicAudioTracks(
        systemAudioTrack,
        micAudioTrack
      );

      mediasoupInstance.replaceAudioTrack(combinedAudioTrack);
    }
    return;
    //  }, 7000);
  };

  startScreenForElectron = (roomId: string) => {
    this.intervalForElectronScreenControl = setInterval(() => {
      if (mediasoupInstance.publishTransport) {
        socketInstanceDesktopApp.sendMessage("APP_REQUEST_OFFER", {
          roomId,
        });
        console.log(
          this.intervalForElectronScreenControl,
          "checkintervalelectron"
        );
        clearInterval(this.intervalForElectronScreenControl);
      }
    }, 1000);
  };

  clearStreamService = () => {
    this._videoInUse = false;
    if (replaceVideoTimeout) {
      clearTimeout(replaceVideoTimeout);
      replaceVideoTimeout = null
    }
    if (this.intervalForElectronScreenControl) {
      clearInterval(this.intervalForElectronScreenControl);
      this.intervalForElectronScreenControl = null;
    }
    if (this.localVideoStream) {
      console.log({ video: this.localVideoStream })
      this.localVideoStream.getTracks().forEach(function (track: any) {
        track.stop();
      });

      this.localVideoStream.getVideoTracks().forEach(function (track: any) {
        track.stop();
      });
      this.localVideoStream = null;
    }
    if (this.localAudioStream) {

      this.localAudioStream.getAudioTracks().forEach(function (track: any) {
        track.stop();
      });
      this.localAudioStream = null;
    }

    this.localScreenStream = undefined;
    this.localSecondScreenStream = undefined;
    if (this.videoTrack) {
      this.videoTrack.stop();
      this.videoTrack = null;
    }
    if (mediasoupInstance.videoProducer) {
      mediasoupInstance.videoProducer.close();
      mediasoupInstance.videoProducer = null; // Clear the reference
    }
    this.peerRemoteStream = {};
    this.disconnectLocalHark();
    this.localAudioFileStream = undefined;
    store.dispatch(setLocalAudioFilePaused(undefined));
    store.dispatch(setLocalScreenFileStopped(undefined))
    this.localScreenFileStream = undefined;
    this.selectedTflite = undefined;
  };
}

export default class StreamServiceSingleton {
  static instance: StreamService;
  constructor() {
    if (!StreamServiceSingleton.instance) {
      StreamServiceSingleton.instance = new StreamService();
    }
  }

  getInstance() {
    return StreamServiceSingleton.instance;
  }
}
